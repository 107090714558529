import React, { useState } from 'react';
import './Checkbox.css';

export default function Checkbox({ id, checked, setCheck }) {
  return (
    <div className="checkbox-wrapper">
      <label>
        <input
          id={id}
          type="checkbox"
          checked={checked}
          className={checked ? 'checked' : ''}
          onChange={() => setCheck((prev) => !prev)}
        />
        <span className="span">
          I agree to{' '}
          <a href="https://kit.global/terms/" className="link" target="_blank">
            Terms of use
          </a>{' '}
          and{' '}
          <a
            href="https://kit.global/privacy-policy/"
            className="link"
            target="_blank"
          >
            Privacy Policy
          </a>
        </span>
      </label>
    </div>
  );
}
