import React, { useState } from 'react';
import s from './Form.module.css';
import logo from '../../images/logo-blue.svg';
import Checkbox from '../Checkbox/Checkbox';
import { useForm } from 'react-hook-form';
import 'react-toastify/dist/ReactToastify.css';
import Modal from '../Modal/Modal';

export default function Form() {
  const [check, setCheck] = useState(false);
  const [modal, setModal] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const postData = (e) => {
    return fetch(
      `https://script.google.com/macros/s/AKfycby1jNyjAnV3_tphXsfTY2EJutaRVcD-rw5QyMYuEnKaPCMrtuo9QgOTnPVktOfPWfcS/exec?p1=${e.name}&p2=${e.companyName}&p3=${e.job}&p4=${e.email}&p5=${e.phone}&p6=${e.country}`,
      {
        method: 'POST',
      }
    )
      .then((res) => res.json())
      .then(() => {
        reset();
      })
      .catch((err) => console.log(err));
  };

  const onSubmit = (e) => {
    postData(e).then(() => {
      setModal(true);
    });
  };

  const closeModal = () => {
    setModal(false);
  };

  return (
    <div className={s.container}>
      <h2 className={s.title}>Get in touch with us</h2>
      <p className={s.subtitle}>
        I'm ready to share your contact details with our Expert team to get a
        free consultation, discuss your project and other questions
      </p>
      <form className={s.form} onSubmit={handleSubmit(onSubmit)}>
        <div className={s.inputs}>
          <label className={`${s.name} ${s.label}`}>
            Name and Surname
            <input
              className={`${s.input} ${errors.name ? s.error : ''}`}
              type="text"
              {...register('name', { required: true })}
            />
          </label>
          <label className={s.label}>
            Company Name
            <input
              type="text"
              className={`${s.input} ${errors.companyName ? s.error : ''}`}
              {...register('companyName', { required: true })}
            />
          </label>
          <label className={s.label}>
            Job title
            <input
              type="text"
              {...register('job', { required: true })}
              className={`${s.input} ${errors.job ? s.error : ''}`}
            />
          </label>
          <label className={s.label}>
            Corporate email
            <input
              type="email"
              {...register('email', { required: true })}
              className={`${s.input} ${errors.email ? s.error : ''}`}
            />
          </label>
          <label className={s.label}>
            Phone
            <input
              type="tel"
              {...register('phone', { required: true })}
              className={`${s.input} ${errors.phone ? s.error : ''}`}
            />
          </label>
          <label className={`${s.country} ${s.label}`}>
            Country
            <input
              type="text"
              {...register('country', { required: true })}
              className={`${s.input} ${errors.country ? s.error : ''}`}
            />
          </label>
        </div>

        <Checkbox id="terms" checked={check} setCheck={setCheck} />
        <button className={s.button} type="submit" disabled={!check}>
          SEND
        </button>
      </form>
      {modal && <Modal closeModal={closeModal} />}
    </div>
  );
}
