import React, { useState, useEffect } from 'react';
import s from './Main.module.css';
import Form from '../../components/Form/Form';
import ReactPlayer from 'react-player';
import axios from 'axios';
import Recorder from '../../components/Recorder/Recorder';
import { v4 as uuidv4 } from 'uuid';
import Chat from '../../components/Chat/Chat';
import Footer from '../../components/Footer/Footer';

export default function Main() {
  if (!localStorage.getItem('customer-uuid')) {
    localStorage.setItem('customer-uuid', uuidv4());
  }
  if (!localStorage.getItem('avatar-customer-uuid')) {
    localStorage.setItem('avatar-customer-uuid', uuidv4());
  }
  const [chatHistory, setChatHistory] = useState();
  const [customerUuid, setcustomerUuid] = useState();
  const [ava, setAva] = useState(true);
  const [isMicroEnabled, setIsMicroEnabled] = useState();
  const [logo, setLogo] = useState();
  const [video, setVideo] = useState();
  const [play, setPlay] = useState();
  const [animation, setAnimation] = useState();
  const [loop, setLoop] = useState();
  const [image, setImage] = useState();
  const [providerType, setProviderType] = useState();
  const [providerVoiceId, setProviderVoiceId] = useState();
  const [peer, setPeer] = useState();
  const [strid, setStrid] = useState();
  const [sessionid, setSessionid] = useState();
  const [finishPrepare, setFinishPrepare] = useState(true);
  const [isTalk, setIsTalk] = useState(false);
  const [messageText, setmessageText] = useState();
  const [message, setMessage] = useState();
  const [videoStart, setVideoStart] = useState();
  const [messages, setMessages] = useState([]);
  const [loaderMessage, setLoaderMessage] = useState(false);

  useEffect(() => {
    if (videoStart) {
      console.log(message);
      addResponseMessage(message);
      setLoaderMessage(false);
    }
  }, [videoStart]);

  let avatar = true;
  const isTest =
    window.location.host.split('.').includes('test') ||
    window.location.host.split('.').includes('localhost:3000');

  const devHost = 'https://app.dev.cleverbots.ae';
  const prodHost = 'https://app.cleverbots.ae';
  const hostName = isTest ? devHost : prodHost;

  const prodBotUid = 'b06e1c51-ff8b-4b6c-a445-cafc499d0ffd';
  const devBotUid = '63bd7c99-e07b-4653-b881-0a9e387c351a';
  const botUid = isTest ? devBotUid : prodBotUid;

  const RTCPeerConnection = (
    window.RTCPeerConnection ||
    window.webkitRTCPeerConnection ||
    window.mozRTCPeerConnection
  ).bind(window);

  let peerConnection;
  let streamId;
  let sessionId;
  let sessionClientAnswer;
  let statsIntervalId;
  let videoIsPlaying;
  let lastBytesReceived;
  const maxRetryCount = 3;
  const maxDelaySec = 4;
  const didToken = 'YWxleEBjbGV2ZXJib3RzLmFl:NCueWoDC4_aIAxyyNWump';

  const addUserMessage = (text) => {
    const messageId = new Date().getTime();
    const message = { text: text, owner: 'user', id: messageId };
    setMessages((messages) => [...messages, message]);
  };

  const addResponseMessage = (text) => {
    const messageId = new Date().getTime();
    const message = { text: text, owner: 'responce', id: messageId };
    setMessages((messages) => [...messages, message]);
    setLoaderMessage(false);
  };

  const getSessionLink = () => {
    return (
      hostName +
      `/api/${avatar ? 'avatar_' : ''}widget/?customer_uuid=${
        avatar
          ? localStorage.getItem('avatar-customer-uuid')
          : localStorage.getItem('customer-uuid')
      }` +
      `&bot_uuid=${botUid}`
    );
  };

  const getMessageLink = () => {
    return hostName + `/api/send_${ava ? 'avatar_' : ''}message/`;
  };

  useEffect(() => {
    axios
      .get(getSessionLink())
      .then((response) => {
        setIsMicroEnabled(response.data.is_audio_enabled);
        addResponseMessage(response.data.start_message);
        setProviderType(response.data.voice_provider);
        setProviderVoiceId(response.data.voice_id);
        setChatHistory(
          response.data.messages.map((item) => {
            addUserMessage(item.question);
            addResponseMessage(item.answer);
          })
        );

        setLogo(response.data.logo_url);
        if (avatar) {
          if (response.data.animation_gif_url) {
            setImage(response.data.animation_gif_url);
          } else {
            setImage(response.data.source_url);
          }
          prepare(response.data.source_url);
          // setInterval(async () => {
          //   prepare(response.data.source_url);
          // }, 1000 * 90);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    if (avatar) {
      setcustomerUuid(localStorage.getItem('avatar-customer-uuid'));
    } else {
      setcustomerUuid(localStorage.getItem('customer-uuid'));
    }
  }, []);

  const handleNewUserMessage = (newMessage) => {
    addUserMessage(newMessage);
    setLoaderMessage(true);
    axios
      .post(getMessageLink(), {
        customer_uuid: customerUuid,
        text: newMessage,
        bot_uuid: botUid,
      })
      .then((response) => {
        setMessage(response.data.answer);
        setmessageText(response.data.did_text);
        return response;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  async function createStream(avatarImageUrl) {
    return fetch(`https://api.d-id.com/talks/streams`, {
      method: 'POST',
      headers: {
        Authorization: `Basic ${didToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        source_url: `${avatarImageUrl}`,
        compatibility_mode: 'on',
      }),
    }).catch((err) => {
      console.log(err);
    });
  }

  async function startStream() {
    return fetch(`https://api.d-id.com/talks/streams/${streamId}/sdp`, {
      method: 'POST',
      headers: {
        Authorization: `Basic ${didToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        answer: sessionClientAnswer,
        session_id: sessionId,
      }),
    });
  }
  async function createPeerConnection(offer, iceServers) {
    if (!peerConnection) {
      peerConnection = new RTCPeerConnection({ iceServers });
      peerConnection.addEventListener(
        'icegatheringstatechange',
        onIceGatheringStateChange,
        true
      );
      peerConnection.addEventListener('icecandidate', onIceCandidate, true);
      peerConnection.addEventListener(
        'iceconnectionstatechange',
        onIceConnectionStateChange,
        true
      );
      peerConnection.addEventListener(
        'connectionstatechange',
        onConnectionStateChange,
        true
      );
      peerConnection.addEventListener(
        'signalingstatechange',
        onSignalingStateChange,
        true
      );

      peerConnection.addEventListener('track', onTrack, true);
    }

    await peerConnection.setRemoteDescription(offer);
    console.log('set remote sdp OK');

    sessionClientAnswer = await peerConnection.createAnswer();
    console.log('create local sdp OK');

    await peerConnection.setLocalDescription(sessionClientAnswer);
    console.log('set local sdp OK');

    setPeer(peerConnection);
    return sessionClientAnswer;
  }

  function onIceCandidate(event) {
    console.log('onIceCandidate', event);
    if (event.candidate) {
      const { candidate, sdpMid, sdpMLineIndex } = event.candidate;

      fetch(`https://api.d-id.com/talks/streams/${streamId}/ice`, {
        method: 'POST',
        headers: {
          Authorization: `Basic ${didToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          candidate,
          sdpMid,
          sdpMLineIndex,
          session_id: sessionId,
        }),
      }).catch((err) => {
        console.log(err);
      });
    }
  }

  function onIceGatheringStateChange() {
    console.log('onIceGatheringStateChange');
  }
  function onIceConnectionStateChange() {
    console.log(
      'onIceConnectionStateChange',
      peerConnection.iceConnectionState
    );
    if (
      peerConnection.iceConnectionState === 'failed' ||
      peerConnection.iceConnectionState === 'closed'
    ) {
      closePC();
    }
  }
  function onConnectionStateChange() {}
  function onSignalingStateChange() {
    console.log('onSignalingStateChange', peerConnection.signalingState);
  }

  const onVideoStatusChange = (videoIsPlaying, stream) => {
    console.log('onSignalingStateChange', videoIsPlaying, stream);
    if (videoIsPlaying) {
      setVideoStart(true);
      setVideo(stream);
    } else {
      setVideoStart(false);
      setIsTalk(false);
      setVideo(image);
    }
  };

  function onTrack(event) {
    if (!event.track) return;

    // peerConnection = peer;
    statsIntervalId = setInterval(async () => {
      if (
        peerConnection &&
        peerConnection.connectionState === 'connected' &&
        peerConnection.iceConnectionState === 'connected'
      ) {
        try {
          // await peerConnection.getTracks(event.track).forEach((report) => {console.log(report)})
          const stats = await peerConnection.getStats(event.track);
          stats.forEach((report) => {
            if (report.type === 'inbound-rtp' && report.mediaType === 'video') {
              const videoStatusChanged =
                videoIsPlaying !== report.bytesReceived > lastBytesReceived;

              if (videoStatusChanged) {
                videoIsPlaying = report.bytesReceived > lastBytesReceived;
                onVideoStatusChange(videoIsPlaying, event.streams[0]);
              }
              lastBytesReceived = report.bytesReceived;
            }
          });
        } catch (err) {}
      }
    }, 500);
  }

  async function prepare(avatarImageUrl) {
    if (isTalk) {
      return;
    }
    setFinishPrepare(false);
    // if (peerConnection && peerConnection.connectionState === 'connected') {
    //     if (
    //     peerConnection.iceConnectionState === 'connected' ||
    //     peerConnection.iceConnectionState === 'stable'
    //   ) {
    //       return;
    //     }
    // }
    closePC();

    const response = await createStream(avatarImageUrl);
    const {
      id: newStreamId,
      offer,
      ice_servers: iceServers,
      session_id: newSessionId,
    } = await response.json();
    streamId = newStreamId;
    setStrid(newStreamId);
    sessionId = newSessionId;
    setSessionid(newSessionId);

    try {
      sessionClientAnswer = await createPeerConnection(offer, iceServers); //.then(r => sessionClientAnswer = r);
    } catch (e) {
      console.log('error during streaming setup', e);
      closePC();
    }

    const startStreamResponse = await startStream();
    await startStreamResponse.json();
    setFinishPrepare(true);
  }

  useEffect(() => {
    if (finishPrepare && messageText) {
      playMessage(messageText);
    }
  }, [finishPrepare, messageText]);

  async function playMessage(text) {
    setIsTalk(true);

    peerConnection = peer;
    // connectionState not supported in firefox
    if (
      peerConnection?.signalingState === 'stable' ||
      peerConnection?.iceConnectionState === 'connected'
    ) {
      const playResponse = await fetchWithRetries(
        `https://api.d-id.com/talks/streams/${strid}`,
        {
          method: 'POST',
          headers: {
            Authorization: `Basic ${didToken}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            script: {
              type: 'text',
              input: text,
              provider: {
                type: providerType,
                voice_id: providerVoiceId,
              },
              ssml: 'false',
            },
            session_id: sessionid,
          }),
        }
      );
    }
    // setmessageText(null);
  }

  function closePC(pc = peerConnection) {
    if (!pc) return;
    console.log('stopping peer connection');
    pc.close();
    pc.removeEventListener(
      'icegatheringstatechange',
      onIceGatheringStateChange,
      true
    );
    pc.removeEventListener('icecandidate', onIceCandidate, true);
    pc.removeEventListener(
      'iceconnectionstatechange',
      onIceConnectionStateChange,
      true
    );
    pc.removeEventListener(
      'connectionstatechange',
      onConnectionStateChange,
      true
    );
    pc.removeEventListener(
      'signalingstatechange',
      onSignalingStateChange,
      true
    );
    pc.removeEventListener('track', onTrack, true);

    clearInterval(statsIntervalId);
    console.log('stopped peer connection');
    if (pc === peerConnection) {
      peerConnection = null;
    }
    setPeer(null);
    peerConnection = null;
  }
  async function fetchWithRetries(url, options, retries = 1) {
    try {
      return await fetch(url, options);
    } catch (err) {
      if (retries <= maxRetryCount) {
        const delay =
          Math.min(Math.pow(2, retries) / 4 + Math.random(), maxDelaySec) *
          1000;

        await new Promise((resolve) => setTimeout(resolve, delay));

        console.log(
          `Request failed, retrying ${retries}/${maxRetryCount}. Error ${err}`
        );
        return fetchWithRetries(url, options, retries + 1);
      } else {
        throw new Error(`Max retries exceeded. error: ${err}`);
      }
    }
  }
  return (
    <div className={s.main}>
      {ava && (
        <div className={s.avatarContainer}>
          <div className={s.avatar}>
            <img src={image} className={s.avatarImage} />
            <ReactPlayer
              style={{ zIndex: '1' }}
              url={video}
              width="100%"
              height="100%"
              loop={loop}
              playing={play}
              onReady={() => setPlay(true)}
              onEnded={() => {
                if (video === animation) {
                  setTimeout(() => {
                    setPlay(true);
                  }, 3000);
                } else {
                  setVideo(animation);
                  setLoop(true);
                }
              }}
            />
          </div>
        </div>
      )}

      <Chat
        messages={messages}
        addUserMessage={addUserMessage}
        handleNewUserMessage={handleNewUserMessage}
        addResponseMessage={addResponseMessage}
        botUid={botUid}
        customerUuid={customerUuid}
        ava={ava}
        setmessageText={setmessageText}
        loaderMessage={loaderMessage}
        hostName={hostName}
        setMessage={setMessage}
      />
      <Footer />
    </div>
  );
}
