import React from 'react';
import s from './Message.module.css';

export default function Message({ owner, text }) {
  return (
    <div className={`${s.message} ${owner === 'user' ? s.user : s.responce}`}>
      <h3>{owner === 'user' ? 'YOU' : 'KAI'}</h3>
      {text ? <p>{text}</p> : <span className={s.loader}></span>}
    </div>
  );
}
