import React from 'react';
import s from './Footer.module.css';
import planet from '../../images/planet-icon.svg';
import bag from '../../images/bag-icon.svg';
import linkedin from '../../images/linkedin-icon.svg';
import insta from '../../images/insta-icon.svg';

export default function Footer() {
  return (
    <div className={s.footer}>
      <a className={s.container} href="https://kit.global" target="_blank">
        <img src={planet} alt="" />
        kit.global
      </a>
      <a
        className={s.container}
        href="https://platform.kit.global/login"
        target="_blank"
      >
        <img src={bag} alt="" />
        platform.kit.global/login
      </a>
      <a
        className={s.container}
        href="https://www.linkedin.com/company/kit-global/"
        target="_blank"
      >
        <img src={linkedin} alt="" />
        kit-global
      </a>
      <a
        className={s.container}
        href="https://www.instagram.com/kit.global.digital/"
        target="_blank"
      >
        <img src={insta} alt="" />
        kit.global.digital
      </a>
    </div>
  );
}
