import React from 'react';
import s from './Modal.module.css';

export default function Modal({ closeModal }) {
  return (
    <div className={s.overlay}>
      <div className={s.container}>
        <button className={s.button} onClick={closeModal}></button>
        <p className={s.text}>
          Thank you for your request. Our team will contact you within 24 hours!
        </p>
      </div>
    </div>
  );
}
