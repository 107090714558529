import s from './App.module.css';
import Main from './pages/Main/Main';
import logo from './images/logo.svg';
import bg from './images/bg.svg';
import Form from './components/Form/Form';

function App() {
  return (
    <div className={s.app}>
      <img src={logo} alt="Logo" className={s.logo} />
      <img src={bg} alt="" className={s.bg} />
      {/* <Form /> */}
      <Main />
      <Form />
    </div>
  );
}

export default App;
