import React from 'react';
import s from './Recorder.module.css';
import MicRecorder from 'mic-recorder-to-mp3';
import axios from 'axios';
import adapter from 'webrtc-adapter';

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isRecording: false,
      blobURL: '',
      isBlocked: false,
    };
  }

  toggleRecord = () => {
    if (this.state.isRecording) {
      this.stop();
      console.log('Stop');
    } else {
      this.start();
      console.log('Start');
    }
  };

  uploadAudio = (blob) => {
    const formData = new FormData();
    formData.append('audio_data', blob, 'file');

    axios
      .post(
        `${this.props.hostName}/api/send_audio_message/`,
        {
          customer_uuid: this.props.customerUuid,
          audio_data: blob,
          bot_uuid: this.props.botUid,
        },
        { headers: { 'content-type': 'multipart/form-data' } }
      )
      .then((res) => {
        this.props.setmessageText(res.data.did_text);
        this.props.addUserMessage(res.data.question);
        this.props.setMessage(res.data.answer);
      });
  };

  start = () => {
    if (this.state.isBlocked) {
      console.log('Permission Denied');
    } else {
      Mp3Recorder.start()
        .then(() => {
          this.setState({ isRecording: true });
        })
        .catch((e) => console.error(e));
    }
  };

  stop = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        const blobURL = URL.createObjectURL(blob);
        this.setState({ blobURL, isRecording: false });

        this.uploadAudio(blob);
      })
      .catch((e) => console.log(e));
  };

  componentDidMount() {
    const browser = adapter.browserDetails.browser;
    if (browser != 'safari') {
      navigator.getUserMedia(
        { audio: true },
        () => {
          console.log('Permission Granted');
          this.setState({ isBlocked: false });
        },
        () => {
          console.log('Permission Denied');
          this.setState({ isBlocked: true });
        }
      );
    }
  }

  render() {
    return (
      <div className={`${s.container} ${this.props.ava ? s.ava : ''}`}>
        <button
          onClick={this.toggleRecord}
          className={`${s.button} ${this.state.isRecording ? s.active : ''}`}
        ></button>
      </div>
    );
  }
}

export default App;
