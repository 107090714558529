import React, { useEffect } from 'react';
import s from './Chat.module.css';
import Recorder from '../Recorder/Recorder';
import sendButton from '../../images/send-btn.svg';
import Message from '../Message/Message';

export default function Chat({
  messages,
  botUid,
  handleNewUserMessage,
  customerUuid,
  addResponseMessage,
  addUserMessage,
  ava,
  setmessageText,
  loaderMessage,
  hostName,
  setMessage,
}) {
  const screenWidth = window.innerWidth;

  const sendData = (e) => {
    e.preventDefault();
    if (e.target.elements.userMessage.value != '') {
      handleNewUserMessage(e.target.elements.userMessage.value, 'user');
      e.target.elements.userMessage.value = '';
    }
  };

  useEffect(() => {
    const element = document.getElementById('messagesEnd');
    element.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
    });
  }, [messages, loaderMessage]);

  return (
    <div className={s.chat}>
      <div className={s.container}>
        <div className={s.messages} id="messages">
          {messages.map((item, index) => {
            return <Message text={item.text} owner={item.owner} key={index} />;
          })}
          {loaderMessage && <Message owner="responce" />}
          <div id="messagesEnd"></div>
        </div>
        <div className={s.tabletContainer}>
          <form
            className={s.label}
            onSubmit={(e) => {
              sendData(e);
            }}
          >
            <input
              type="text"
              className={s.input}
              placeholder="Enter text / start speaking"
              name="userMessage"
            />
            <button className={s.button} type="submit">
              <img src={sendButton} alt="Send button" />
            </button>
          </form>
          {screenWidth < 900 && (
            <Recorder
              customerUuid={customerUuid}
              botUid={botUid}
              ava={ava}
              addResponseMessage={addResponseMessage}
              addUserMessage={addUserMessage}
              setmessageText={setmessageText}
              setMessage={setMessage}
              hostName={hostName}
            />
          )}
        </div>
      </div>
      {screenWidth > 900 && (
        <Recorder
          customerUuid={customerUuid}
          botUid={botUid}
          ava={ava}
          addResponseMessage={addResponseMessage}
          addUserMessage={addUserMessage}
          setmessageText={setmessageText}
          setMessage={setMessage}
          hostName={hostName}
        />
      )}
    </div>
  );
}
